import { defineStore } from "pinia";
import {
  processCart,
  validateProduct,
  nuevaVenta,
  showPayButton,
  cancelarVenta,
  actualizarVenta,
  cambiarMetodoPago,
} from "@api/cart_api.js";

export const shoppingCartStore = defineStore("shoppingCartStore", {
  state: () => ({
    loadingShoppingCart: false,
    showShoppingCart: false,
    shoppingCartItems: [],
    shoppingCartItemCount: 0,
    shoppingCartTotal: 0,
    idVenta: "",
    tipoPago: "",
  }),
  actions: {
    async expiredCheck() {
      if (typeof Storage !== undefined) {
        if (JSON.parse(localStorage.getItem("basterShopingCart")) !== null) {
          this.shoppingCartItems = JSON.parse(
            localStorage.getItem("basterShopingCart")
          );

          let countExpired = 0;
          let productosExpired = [];

          this.shoppingCartItems.forEach((item) => {
            if (moment() >= moment(item.expires)) {
              countExpired++;
              productosExpired.push(item.item.name);
              this.removeFromShoppingCart(item);
            }
          });

          if (countExpired > 0) {
            let strProdctsName = "";
            productosExpired.forEach((item) => {
              strProdctsName += " * " + item + "\n";
            });
            let strMsg =
              "Tienes " +
              countExpired +
              " producto(s) que han expirado en el carrito de compras y han sido quitados:\n\n" +
              strProdctsName;
            alert(strMsg);
            this.initShoppingCart();
          }
        }
      } else {
        alert("Almacenamiento local no disponible!!!");
      }
    },

    async initShoppingCart() {
      if (typeof Storage !== undefined) {
        if (JSON.parse(localStorage.getItem("basterShopingCart")) !== null) {
          this.shoppingCartItems = JSON.parse(
            localStorage.getItem("basterShopingCart")
          );

          let qtty = 0;
          let total = 0;
          this.shoppingCartItems.forEach((item) => {
            qtty++;
            total += parseInt(item.qtty) * (item.price * 1);
          });

          this.shoppingCartItemCount = qtty;
          this.shoppingCartTotal = total;
        }
      } else {
        alert("Almacenamiento local no disponible!!!");
      }
    },

    async toggleShoppingCartModal() {
      this.showShoppingCart = !this.showShoppingCart;
      if (!this.showShoppingCart) {
        this.resetShoppingCart();
      }
    },

    showShoppingCartModal() {
      this.showShoppingCart = true;
    },

    hideShoppingCartModal() {
      this.showShoppingCart = false;
    },

    async resetShoppingCart() {
      if (typeof Storage !== undefined) {
        this.shoppingCartItems = [];
        this.shoppingCartItemCount = 0;
        this.shoppingCartTotal = 0;
        this.idVenta = "";
        this.tipoPago = "";
        localStorage.setItem(
          "basterShopingCart",
          JSON.stringify(this.shoppingCartItems)
        );
        localStorage.setItem("saleId", "");
        localStorage.setItem("preferenceId", "");
        localStorage.setItem("cartModified", false);
      } else {
        alert("Almacenamiento local no disponible!!!");
      }
    },

    async addToShoppingCart(product) {
      if (typeof Storage !== undefined) {
        let item = {
          id: product.id,
          ppId: "valor de prueba",
          Presentacion: "valor de prueba",
          item: product,
          price: product.price,
          qtty: 1,
          expires: moment().add(12, "hours"),
        };

        if (JSON.parse(localStorage.getItem("basterShopingCart")) === null) {
          this.shoppingCartItems = [item];
          localStorage.setItem(
            "basterShopingCart",
            JSON.stringify(this.shoppingCartItems)
          );
        } else {
          let index = this.shoppingCartItems.findIndex(
            (item) => item.ppId == product.ppId
          );

          if (index != -1) {
            this.shoppingCartItems[index].qtty += 1;
            this.shoppingCartItems[index].expires = moment().add(12, "hours");
          } else {
            this.shoppingCartItems.push(item);
          }

          localStorage.setItem(
            "basterShopingCart",
            JSON.stringify(this.shoppingCartItems)
          );
        }
      } else {
        alert("Almacenamiento local no disponible!!!");
      }
    },

    async removeFromShoppingCart(product) {
      let auxCart = [];
      if (typeof Storage !== undefined) {
        auxCart = JSON.parse(localStorage.getItem("basterShopingCart"));
        auxCart = auxCart.filter((item) => item.ppId != product.ppId);

        localStorage.setItem("basterShopingCart", JSON.stringify(auxCart));
        if (auxCart == []) {
          this.resetShoppingCart();
        }
      } else {
        alert("Almacenamiento local no disponible!!!");
      }
    },

    async updateShoppingCart(
      product,
      qtty,
      ppId,
      Presentacion,
      PreciosPresentacion
    ) {
      let auxCart = [];

      if (typeof Storage !== undefined) {
        let thePrice = 0;

        PreciosPresentacion.forEach((price) => {
          if (qtty >= price.Min) {
            thePrice = price.Price * 1;
            if (price.promo_descuento_perc != 0 && price.show_promo) {
              thePrice =
                price.Price - (price.Price * price.promo_descuento_perc) / 100;
            }
          }
        });

        let item = {
          id: product.id,
          ppId: ppId,
          Presentacion: Presentacion,
          PreciosPresentacion: PreciosPresentacion,
          item: product,
          price: thePrice,
          qtty: qtty,
          categoria_pago_requerido: product.categoria_pago_requerido,
          subCategoria_pago_requerido: product.subCategoria_pago_requerido,
          expires: moment().add(12, "hours"),
        };

        if (JSON.parse(localStorage.getItem("basterShopingCart")) === null) {
          auxCart = [item];
          localStorage.setItem("basterShopingCart", JSON.stringify(auxCart));
        } else {
          auxCart = JSON.parse(localStorage.getItem("basterShopingCart"));
          let index = auxCart.findIndex((item) => item.ppId == ppId);

          if (index != -1) {
            if (qtty > 0) {
              let thePrice2 = 0;
              auxCart[index].PreciosPresentacion.forEach((price) => {
                if (qtty >= price.Min) {
                  thePrice2 = price.Price * 1;
                  if (price.promo_descuento_perc != 0 && price.show_promo) {
                    thePrice2 =
                      price.Price -
                      (price.Price * price.promo_descuento_perc) / 100;
                  }
                }
              });

              auxCart[index].qtty = qtty;
              auxCart[index].expires = moment().add(12, "hours");
              auxCart[index].price = thePrice2;
            } else {
              auxCart = auxCart.filter((item) => item.ppId != ppId);
            }
          } else {
            auxCart.push(item);
          }

          localStorage.setItem("basterShopingCart", JSON.stringify(auxCart));
        }
      } else {
        alert("Almacenamiento local no disponible!!!");
      }
    },

    async mostrarDisponibilidad(data) {
      let auxCart = [];
      if (typeof Storage !== undefined) {
        data.forEach((product) => {
          auxCart = JSON.parse(localStorage.getItem("basterShopingCart"));

          let index = auxCart.findIndex((item) => item.ppId == product.ppId);
          if (index != -1) {
            var antAvailable = auxCart[index].available;
            auxCart[index].available = product.stock;
            auxCart[index].qtty = product.qtty;
          }
          localStorage.setItem("basterShopingCart", JSON.stringify(auxCart));
          if (
            typeof antAvailable == "undefined" &&
            product.qtty > product.stock
          ) {
            document
              .getElementById("idDivAvStock_" + product.ppId)
              .classList.remove("d-none");
            document.getElementById("idAvStock_" + product.ppId).innerHTML =
              "Cantidad máxima disponible: " + product.stock + " unidades";
          }
        });
      } else {
        alert("Almacenamiento local no disponible!!!");
      }
    },

    async updateData() {
      this.shoppingCartItems = JSON.parse(
        localStorage.getItem("basterShopingCart")
      );

      let qtty = 0;
      let total = 0;
      this.shoppingCartItems.forEach((item) => {
        qtty++;
        total += parseInt(item.qtty) * (item.price * 1);
      });

      this.shoppingCartItemCount = qtty;
      this.shoppingCartTotal = total;
    },

    async processCart(data) {
      const response = await processCart(data);

      switch (response.code) {
        case 403:
          alert("Error en la entrada de datos");
          return false;
          break;
        case 401:
          alert("Autenticación requerida");
          return false;
          break;
        case 402:
          alert("No hay productos en el almacén de pirotecnia");
          return false;
          break;
        case 423:
          alert("Error al procesar los productos del carrito");
          return false;
          break;
        default:
          if (response.status === 200) {
            return response.data;
          } else {
            alert("Error al procesar los productos del carrito");
            return false;
          }
      }
    },

    async validateProduct(data) {
      const response = await validateProduct(data);

      switch (response.code) {
        case 403:
          alert("Error en la entrada de datos");
          return false;
          break;
        case 401:
          alert("Autenticación requerida");
          return false;
          break;
        case 402:
          alert("No hay productos en el almacén de pirotecnia");
          return false;
          break;
        case 423:
          alert("Error al procesar los datos del producto");
          return false;
          break;
        default:
          if (response.status === 200) {
            return response.data;
          } else {
            alert("Error al procesar los datos del producto");
            return false;
          }
      }
    },

    async cancelarVenta(data) {
      const response = await cancelarVenta(data);

      switch (response.code) {
        case 403:
          alert("Error en la entrada de datos");
          return false;
          break;
        case 401:
          alert("Autenticación requerida");
          return false;
          break;
        case 423:
          alert("Error al cancelar la venta");
          return false;
          break;
        default:
          if (response.status === 200) {
            return response.data;
          } else {
            alert("Error al cancelar la venta");
            return false;
          }
      }
    },

    async nuevaVenta(data) {
      const response = await nuevaVenta(data);
      switch (response.code) {
        case 403:
          alert("Error en la entrada de datos");
          return false;
          break;
        case 401:
          alert("Autenticación requerida");
          return false;
          break;
        case 423:
          alert("Error al procesar los productos del carrito");
          return false;
          break;
        default:
          if (response.status === 200) {
            if (data.tipoPago == "MercadoPago") {
              document.getElementById("loadingText").innerHTML =
                "Venta realizada (No. " +
                response.data +
                ") <br/> Procediendo a realizar el pago";
              return response;
            } else {
              let spinner = document.getElementById("loadingSpinner");
              if (spinner) {
                spinner.classList.add("d-none");
              }
              let loadingText = document.getElementById("loadingText");
              if (loadingText) {
                loadingText.classList.add("d-none");
                document.getElementById("loadingText").innerHTML =
                  "Venta realizada (No. " + response.data + ")";
              }

              this.idVenta = response.data;
              this.tipoPago = data.tipoPago;
            }

            return true;
          } else {
            alert("Error al procesar los productos del carrito");
            return false;
          }
      }
    },

    setTipoPago(tipoPago) {
      this.tipoPago = tipoPago;
    },

    async actualizarVenta(data) {
      const response = await actualizarVenta(data);
      switch (response.code) {
        case 403:
          alert("Error en la entrada de datos");
          return false;
          break;
        case 401:
          alert("Autenticación requerida");
          return false;
          break;
        case 423:
          alert("Error al procesar los productos del carrito");
          return false;
          break;
        default:
          if (response.status === 200) {
            if (data.tipoPago == "MercadoPago") {
              document.getElementById("loadingText").innerHTML =
                "Venta realizada (No. " +
                response.data +
                ") <br/> Procediendo a realizar el pago";
              return response;
            } else {
              document
                .getElementById("loadingSpinner")
                ?.classList.add("d-none");
              document.getElementById("loadingText")?.classList.add("text-end");
              document.getElementById("loadingText").innerHTML =
                "Venta realizada (No. " + response.data + ")";
              this.idVenta = response.data;
              this.tipoPago = data.tipoPago;
            }
            return true;
          } else {
            alert("Error al procesar los productos del carrito");
            return false;
          }
      }
    },

    async cambiarMetodoPago(data) {
      const response = await cambiarMetodoPago(data);

      switch (response.code) {
        case 403:
          alert("Error en la entrada de datos");
          return false;
          break;
        case 401:
          alert("Autenticación requerida");
          return false;
          break;
        case 402:
          alert("Error en el método de Pago");
          return false;
          break;
        case 423:
          alert("Error al cambiar el método de Pago");
          return false;
          break;
        default:
          if (response.status === 200) {
            return response.data;
          } else {
            alert("Error al cambiar el método de Pago");
            return false;
          }
      }
    },

    async MPButtonPay(data, response) {
      //Payload to pay-button
      var myCartItems = {};
      var cartItems = [];

      for (let i = 0; i < data.cartItems.length; i++) {
        cartItems[i] = {
          id: data.cartItems[i].id,
          name:
            data.cartItems[i].item.name + " " + data.cartItems[i].Presentacion,
          qty: parseInt(data.cartItems[i].qtty),
          price: parseFloat(data.cartItems[i].price),
          prices: data.cartItems[i].item.prices,
          categoryRequirePayment: false,
          subCategoryRequirePayment: false,
          isPirotecnia:
            data.cartItems[i].category == "pirotecnia" ? true : false,
          PresentacionProductoId: data.cartItems[i].ppId,
          total:
            parseFloat(data.cartItems[i].price) *
            parseInt(data.cartItems[i].qtty),
        };
      }
      myCartItems.cartItems = cartItems;

      if (typeof Storage !== undefined) {
        var email = localStorage.getItem("email")
          ? localStorage.getItem("email")
          : null;
      } else {
        return alert("Almacenamiento local no disponible!!!");
      }

      let myData = {
        cartItems: myCartItems.cartItems,
        saleId: response.data,
        email: email,
      };
      return await showPayButton(myData);
    },
  },
});
