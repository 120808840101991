import { defineStore } from "pinia";
import {
  getCategories,
  getSubcategories,
  getPromos,
  getFeatured,
  getProductsPaginated,
  getProductBySlug,
  searchProducts,
  getProductBySubcat,
  getProductByCat,
} from "@api/inventory_api.js";

export const inventoryStore = defineStore("inventoryStore", {
  state: () => ({
    status: 200,
    loadingCategories: false,
    loadingSubcategories: false,
    loadingProducts: false,
    loadingProductDetail: false,
    searchingProducts: false,
    searching: false,
    criteria: "",
    categories: [],
    categorySelected: null,
    subcategories: [],
    subcategorySelected: null,
    productsOnPromo: [],
    productsFeatured: [],
    productBuffer: [],
    products: [],
    productSelected: null,
    productsSearchResult: [],
    crumbs: [],
    all_prices: [],
    provincias: [],
    localidades: [],
    formasPago: [],
    condicionesIva: [],
    productCurrentPage: 1,
    productLastPage: 1,
  }),
  actions: {
    //----------------------------------------------------------------------------- categories
    async getCategories() {
      this.loadingCategories = true;

      const response = await getCategories();
      this.status = response.status;

      if (response.status === 200) {
        this.categories = response.data.map((item) => {
          return {
            id: item.id,
            nombre: item.nombre,
            slug: item.slug,
            image: item.imagen?.imagen
              ? `${import.meta.env.VITE_IMAGES_BASE_URL}/images/thumbs/${
                  item.imagen.imagen.image_name
                }`
              : null,
            sub_rubros: item.sub_rubros,
          };
        });
      } else {
        console.log(response.error);
      }

      this.loadingCategories = false;
    },

    setCategory(id) {
      this.categorySelected = id;
    },

    //----------------------------------------------------------------------------- subcategories
    async getSubcategories(catId) {
      this.loadingSubcategories = true;

      const response = await getSubcategories(catId);
      this.status = response.status;

      if (response.status === 200) {
        this.subcategories = response.data.map((item) => {
          return {
            id: item.id,
            nombre: item.nombre,
            slug: item.slug,
            image: item.imagen?.imagen
              ? `${import.meta.env.VITE_IMAGES_BASE_URL}/images/thumbs/${
                  item.imagen.imagen.image_name
                }`
              : null,
          };
        });
      } else {
        console.log(response.error);
      }

      this.loadingSubcategories = false;
    },
    setSubcategory(id) {
      this.subcategorySelected = id;
    },

    //----------------------------------------------------------------------------- products
    async getPromos() {
      this.productsOnPromo = [];

      const response = await getPromos();
      this.status = response.status;

      if (response.status === 200) {
        this.productsOnPromo = response.data.data.map((item) => {
          var discount =
            item.promo_descuento_perc != 0
              ? item.prices[0].Price * (item.promo_descuento_perc / 100)
              : 0;

          // check date of promotions
          var show_promo = false;
          if (item.promo_vencimiento_desde && item.promo_vencimiento_hasta) {
            var d = new Date(),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            var _now = [year, month, day].join("-");

            if (
              item.promo_vencimiento_desde <= _now &&
              item.promo_vencimiento_hasta >= _now
            ) {
              show_promo = true;
            }
          }

          return {
            id: item.id,
            name: item.name,
            quantity: item.quantity,
            description: item.description,
            category: item.category,
            slug: item.slug_producto,
            image: `${import.meta.env.VITE_IMAGES_BASE_URL}/images/thumbs/${
              item.pictures[0]
            }`,
            price: item.prices[0].Price,
            prices: item.prices,
            show_promo: show_promo,
            promo_descuento_perc: item.promo_descuento_perc,
            discount: discount,
            promo_price: item.prices[0].Price - discount,
            href: `/productos/${item.slug_producto}`,
            presentaciones: item.presentaciones,
            arr_prices: item.arr_prices,
            marca: item.marca,
            categoria_pago_requerido: item.categoria_pago_requerido,
            subCategoria_pago_requerido: item.subCategoria_pago_requerido,
          };
        });
      } else {
        console.log(response.error);
      }
    },

    async getFeatured() {
      this.productsFeatured = [];

      const response = await getFeatured();
      this.status = response.status;

      if (response.status === 200) {
        this.productsFeatured = response.data.data.map((item) => {
          var discount =
            item.promo_descuento_perc != 0
              ? item.prices[0].Price * (item.promo_descuento_perc / 100)
              : 0;

          // check date of promotions
          var show_promo = false;
          if (item.promo_vencimiento_desde && item.promo_vencimiento_hasta) {
            var d = new Date(),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            var _now = [year, month, day].join("-");

            if (
              item.promo_vencimiento_desde <= _now &&
              item.promo_vencimiento_hasta >= _now
            ) {
              show_promo = true;
            }
          }

          return {
            id: item.id,
            name: item.name,
            quantity: item.quantity,
            description: item.description,
            category: item.category,
            subcategory: item.subCategory,
            slug: item.slug_producto,
            image: `${import.meta.env.VITE_IMAGES_BASE_URL}/images/thumbs/${
              item.pictures[0]
            }`,
            price: item.prices[0].Price,
            prices: item.prices,
            show_promo: show_promo,
            promo_descuento_perc: item.promo_descuento_perc,
            discount: discount,
            promo_price: item.prices[0].Price - discount,
            href: `/productos/${item.slug_producto}`,
            presentaciones: item.presentaciones,
            arr_prices: item.arr_prices,
            marca: item.marca,
            categoria_pago_requerido: item.categoria_pago_requerido,
            subCategoria_pago_requerido: item.subCategoria_pago_requerido,
          };
        });
      } else {
        console.log(response.error);
      }
    },

    async getProductPaginated(perPage, page) {
      this.loadingProducts = true;
      this.productsSearchResult = [];
      this.productBuffer = [];
      const response = await getProductsPaginated(perPage, page);

      this.status = response.status;
      this.message = response.message;

      if (response.status === 200) {
        this.productCurrentPage = response.data.current_page;
        this.productLastPage = response.data.last_page;

        this.productBuffer = response.data.data.map((item) => {
          var discount =
            item.promo_descuento_perc != 0
              ? item.prices[0].Price * (item.promo_descuento_perc / 100)
              : 0;

          // check date of promotions
          var show_promo = false;
          if (item.promo_vencimiento_desde && item.promo_vencimiento_hasta) {
            var d = new Date(),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            var _now = [year, month, day].join("-");

            if (
              item.promo_vencimiento_desde <= _now &&
              item.promo_vencimiento_hasta >= _now
            ) {
              show_promo = true;
            }
          }

          var my_images =
            item.pictures.length > 0
              ? item.pictures.map((picture, index) => {
                  var type =
                    item.mime_type.length > 0 && item.mime_type[index] != null
                      ? item.mime_type[index]
                      : "";
                  var is_video = type.includes("video") ? true : false;
                  var ext = type.split("/").pop();

                  return is_video
                    ? `${import.meta.env.VITE_IMAGES_BASE_URL}/storage/videos/${
                        item.image_hash[index]
                      }.${ext}`
                    : `${
                        import.meta.env.VITE_IMAGES_BASE_URL
                      }/images/thumbs/${picture}`;
                })
              : [];

          return {
            id: item.id,
            name: item.name,
            quantity: item.quantity,
            slug: item.slug_producto,
            description: item.description,
            image: my_images[0] != undefined ? my_images[0] : null,
            pictures: my_images,
            mime_type:
              typeof item.mime_type !== "undefined"
                ? item.mime_type.length > 0
                  ? item.mime_type.map((type) => `${type}`)
                  : []
                : [],
            url: item.url?.map((item) =>
              item !== null
                ? `${
                    item.indexOf("?v=") != -1
                      ? "https://www.youtube.com/embed/" +
                        item.substring(item.indexOf("=") + 1, item.length)
                      : item.indexOf("?si=") != -1
                      ? "https://www.youtube.com/embed/" +
                        item.substring(
                          item.indexOf("?si="),
                          item.lastIndexOf("/") + 1
                        )
                      : item.indexOf("embed") != -1
                      ? "https://www.youtube.com/embed/" +
                        item.substring(item.lastIndexOf("/") + 1, item.length)
                      : "https://www.youtube.com/embed/" +
                        item.substring(item.lastIndexOf("/") + 1, item.length)
                  }`
                : item
            ),
            for_video: `${
              import.meta.env.VITE_IMAGES_BASE_URL
            }/images/thumbs/video_preview.png`,
            category: item.category,
            category_id: item.category_id,
            subcategory: item.subCategory,
            subcategory_id: item.sub_category_id,
            price: item.prices[0].Price,
            prices: item.prices,
            show_promo: show_promo,
            promo_descuento_perc: item.promo_descuento_perc,
            discount: discount,
            promo_price: item.prices[0].Price - discount,
            href: `/productos/${item.slug_producto}`,
            presentaciones: item.presentaciones,
            arr_prices: item.arr_prices,
            marca: item.marca,
            categoria_pago_requerido: item.categoria_pago_requerido,
            subCategoria_pago_requerido: item.subCategoria_pago_requerido,
          };
        });

        if (this.productLastPage < page) {
          this.loadingProducts = false;
        }
      } else {
        console.log(response.error);
      }
    },

    async selectProduct(id) {
      this.productSelected = null;
      this.products.forEach((item) => {
        if (item.id == id) {
          this.productSelected = item;
        }
      });
    },

    setCriteria(criteria) {
      this.criteria = criteria;
    },

    async getProductBySubcategory(categoryId, subcategoryId) {
      this.searchingProducts = true;
      this.loadingProducts = true;

      this.productsSearchResult = [];

      this.products = this.products.filter((item) => item != null);
      this.products = this.products == "" ? [] : this.products;

      if (this.productsSearchResult.length == 0) {
        const response = await getProductBySubcat(categoryId, subcategoryId);
        this.status = response.status;

        if (response.status === 200) {
          if (response.data.data.length != 0) {
            this.products = response.data.data.map((item) => {
              var discount =
                item.promo_descuento_perc != 0
                  ? item.prices[0].Price * (item.promo_descuento_perc / 100)
                  : 0;

              // check date of promotions
              var show_promo = false;
              if (
                item.promo_vencimiento_desde &&
                item.promo_vencimiento_hasta
              ) {
                var d = new Date(),
                  month = "" + (d.getMonth() + 1),
                  day = "" + d.getDate(),
                  year = d.getFullYear();
                if (month.length < 2) month = "0" + month;
                if (day.length < 2) day = "0" + day;
                var _now = [year, month, day].join("-");

                if (
                  item.promo_vencimiento_desde <= _now &&
                  item.promo_vencimiento_hasta >= _now
                ) {
                  show_promo = true;
                }
              }

              var my_images =
                item.pictures.length > 0
                  ? item.pictures.map((picture, index) => {
                      var type =
                        item.mime_type.length > 0 &&
                        item.mime_type[index] != null
                          ? item.mime_type[index]
                          : "";
                      var is_video = type.includes("video") ? true : false;
                      var ext = type.split("/").pop();

                      return is_video
                        ? `${
                            import.meta.env.VITE_IMAGES_BASE_URL
                          }/storage/videos/${item.image_hash[index]}.${ext}`
                        : `${
                            import.meta.env.VITE_IMAGES_BASE_URL
                          }/images/thumbs/${picture}`;
                    })
                  : [];

              return {
                id: item.id,
                name: item.name,
                quantity: item.quantity,
                slug: item.slug_producto,
                description: item.description,
                image: my_images[0] != undefined ? my_images[0] : null,
                pictures: my_images,
                mime_type:
                  typeof item.mime_type !== "undefined"
                    ? item.mime_type.length > 0
                      ? item.mime_type.map((type) => `${type}`)
                      : []
                    : [],
                url: item.url?.map((item) =>
                  item !== null
                    ? `${
                        item.indexOf("?v=") != -1
                          ? "https://www.youtube.com/embed/" +
                            item.substring(item.indexOf("=") + 1, item.length)
                          : item.indexOf("?si=") != -1
                          ? "https://www.youtube.com/embed/" +
                            item.substring(
                              item.indexOf("?si="),
                              item.lastIndexOf("/") + 1
                            )
                          : item.indexOf("embed") != -1
                          ? "https://www.youtube.com/embed/" +
                            item.substring(
                              item.lastIndexOf("/") + 1,
                              item.length
                            )
                          : "https://www.youtube.com/embed/" +
                            item.substring(
                              item.lastIndexOf("/") + 1,
                              item.length
                            )
                      }`
                    : item
                ),
                for_video: `${
                  import.meta.env.VITE_IMAGES_BASE_URL
                }/images/thumbs/video_preview.png`,
                category: item.category,
                category_id: item.category_id,
                subcategory: item.subCategory,
                subcategory_id: item.sub_category_id,
                price: item.prices[0].Price,
                prices: item.prices,
                show_promo: show_promo,
                promo_descuento_perc: item.promo_descuento_perc,
                discount: discount,
                promo_price: item.prices[0].Price - discount,
                href: `/productos/${item.slug_producto}`,
                presentaciones: item.presentaciones,
                arr_prices: item.arr_prices,
                marca: item.marca,
                categoria_pago_requerido: item.categoria_pago_requerido,
                subCategoria_pago_requerido: item.subCategoria_pago_requerido,
              };
            });

            //this.productsSearchResult = this.products

            console.log("Get Products by Subcategory from API");
          } else {
            this.products = [];
            this.productsSearchResult = [];
            console.log("There are no products in this subcategory");
          }
        } else {
          console.log(response.error);
        }
      }

      this.searchingProducts = false;
      this.loadingProducts = false;
    },

    async getProductByCategory(categoryId, page) {
      this.searchingProducts = true;
      this.loadingProducts = true;

      this.productsSearchResult = [];

      const response = await getProductByCat(categoryId, page);
      this.status = response.status;

      if (response.status === 200) {
        var last_page = response.data.last_page;
        var current_page = response.data.current_page;
        if (response.data.data.length != 0) {
          this.products = response.data.data.map((item) => {
            var discount =
              item.promo_descuento_perc != 0
                ? item.prices[0].Price * (item.promo_descuento_perc / 100)
                : 0;

            // check date of promotions
            var show_promo = false;
            if (item.promo_vencimiento_desde && item.promo_vencimiento_hasta) {
              var d = new Date(),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();
              if (month.length < 2) month = "0" + month;
              if (day.length < 2) day = "0" + day;
              var _now = [year, month, day].join("-");

              if (
                item.promo_vencimiento_desde <= _now &&
                item.promo_vencimiento_hasta >= _now
              ) {
                show_promo = true;
              }
            }

            var my_images =
              item.pictures.length > 0
                ? item.pictures.map((picture, index) => {
                    var type =
                      item.mime_type.length > 0 && item.mime_type[index] != null
                        ? item.mime_type[index]
                        : "";
                    var is_video = type.includes("video") ? true : false;
                    var ext = type.split("/").pop();

                    return is_video
                      ? `${
                          import.meta.env.VITE_IMAGES_BASE_URL
                        }/storage/videos/${item.image_hash[index]}.${ext}`
                      : `${
                          import.meta.env.VITE_IMAGES_BASE_URL
                        }/images/thumbs/${picture}`;
                  })
                : [];

            return {
              id: item.id,
              name: item.name,
              quantity: item.quantity,
              slug: item.slug_producto,
              description: item.description,
              image: my_images[0] != undefined ? my_images[0] : null,
              pictures: my_images,
              mime_type:
                typeof item.mime_type !== "undefined"
                  ? item.mime_type.length > 0
                    ? item.mime_type.map((type) => `${type}`)
                    : []
                  : [],
              url: item.url?.map((item) =>
                item !== null
                  ? `${
                      item.indexOf("?v=") != -1
                        ? "https://www.youtube.com/embed/" +
                          item.substring(item.indexOf("=") + 1, item.length)
                        : item.indexOf("?si=") != -1
                        ? "https://www.youtube.com/embed/" +
                          item.substring(
                            item.indexOf("?si="),
                            item.lastIndexOf("/") + 1
                          )
                        : item.indexOf("embed") != -1
                        ? "https://www.youtube.com/embed/" +
                          item.substring(item.lastIndexOf("/") + 1, item.length)
                        : "https://www.youtube.com/embed/" +
                          item.substring(item.lastIndexOf("/") + 1, item.length)
                    }`
                  : item
              ),
              for_video: `${
                import.meta.env.VITE_IMAGES_BASE_URL
              }/images/thumbs/video_preview.png`,
              category: item.category,
              category_id: item.category_id,
              subcategory: item.subCategory,
              subcategory_id: item.sub_category_id,
              price: item.prices[0].Price,
              prices: item.prices,
              show_promo: show_promo,
              promo_descuento_perc: item.promo_descuento_perc,
              discount: discount,
              promo_price: item.prices[0].Price - discount,
              href: `/productos/${item.slug_producto}`,
              presentaciones: item.presentaciones,
              arr_prices: item.arr_prices,
              marca: item.marca,
              categoria_pago_requerido: item.categoria_pago_requerido,
              subCategoria_pago_requerido: item.subCategoria_pago_requerido,
              last_page: last_page,
              current_page: current_page,
            };
          });

          //this.productsSearchResult = this.products

          console.log("Get Products by Category from API");
        } else {
          this.productsSearchResult = [];
          console.log("There are no products in this category");
        }
      } else {
        console.log(response.error);
      }

      this.searchingProducts = false;
      this.loadingProducts = false;
    },

    async getProductBySlug(slug) {
      this.loadingProductDetail = true;

      const response = await getProductBySlug(slug);
      this.status = response.status;

      if (response.status === 200) {
        this.productBuffer = response.data.data.map((item) => {
          var discount =
            item.promo_descuento_perc != 0
              ? item.prices[0].Price * (item.promo_descuento_perc / 100)
              : 0;

          // check date of promotions
          var show_promo = false;
          if (item.promo_vencimiento_desde && item.promo_vencimiento_hasta) {
            var d = new Date(),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            var _now = [year, month, day].join("-");

            if (
              item.promo_vencimiento_desde <= _now &&
              item.promo_vencimiento_hasta >= _now
            ) {
              show_promo = true;
            }
          }

          var my_images =
            item.pictures.length > 0
              ? item.pictures.map((picture, index) => {
                  var type =
                    item.mime_type.length > 0 && item.mime_type[index] != null
                      ? item.mime_type[index]
                      : "";
                  var is_video = type.includes("video") ? true : false;
                  var ext = type.split("/").pop();
                  return is_video
                    ? `${import.meta.env.VITE_IMAGES_BASE_URL}/storage/videos/${
                        item.image_hash[index]
                      }.${ext}`
                    : `${
                        import.meta.env.VITE_IMAGES_BASE_URL
                      }/images/thumbs/${picture}`;
                })
              : [];

          return {
            id: item.id,
            name: item.name,
            quantity: item.quantity,
            slug: item.slug_producto,
            description: item.description,
            image: my_images[0] != undefined ? my_images[0] : null,
            pictures: my_images,
            mime_type:
              typeof item.mime_type !== "undefined"
                ? item.mime_type.length > 0
                  ? item.mime_type.map((type) => `${type}`)
                  : []
                : [],
            url: item.url?.map((item) =>
              item !== null
                ? `${
                    item.indexOf("?v=") != -1
                      ? "https://www.youtube.com/embed/" +
                        item.substring(item.indexOf("=") + 1, item.length)
                      : item.indexOf("?si=") != -1
                      ? "https://www.youtube.com/embed/" +
                        item.substring(
                          item.indexOf("?si="),
                          item.lastIndexOf("/") + 1
                        )
                      : item.indexOf("embed") != -1
                      ? "https://www.youtube.com/embed/" +
                        item.substring(item.lastIndexOf("/") + 1, item.length)
                      : "https://www.youtube.com/embed/" +
                        item.substring(item.lastIndexOf("/") + 1, item.length)
                  }`
                : item
            ),
            for_video: `${
              import.meta.env.VITE_IMAGES_BASE_URL
            }/images/thumbs/video_preview.png`,
            category: item.category,
            category_id: item.category_id,
            subcategory: item.subCategory,
            subcategory_id: item.sub_category_id,
            price: item.prices[0].Price,
            prices: item.prices,
            show_promo: show_promo,
            promo_descuento_perc: item.promo_descuento_perc,
            discount: discount,
            promo_price: item.prices[0].Price - discount,
            href: `/productos/${item.slug_producto}`,
            presentaciones: item.presentaciones,
            arr_prices: item.arr_prices,
            marca: item.marca,
            categoria_pago_requerido: item.categoria_pago_requerido,
            subCategoria_pago_requerido: item.subCategoria_pago_requerido,
          };
        });

        if (this.productBuffer.length) {
          this.productSelected = this.productBuffer[0];
          this.all_prices.value = [];
          this.productBuffer[0].arr_prices.map((prices) =>
            prices.map((price) => this.all_prices.value.push(price))
          );
          console.log("from net prod by slug");
        }
      } else {
        console.log(response.error);
      }

      this.loadingProductDetail = false;
    },

    async searchProduct() {
      this.searchingProducts = true;
      if (this.criteria) {
        console.log("searching...");
        this.searching = true;

        // filter by criteria
        var needle = this.criteria.toLowerCase();
        console.log("Search products from API");
        const response = await searchProducts(needle);
        this.status = response.status;

        if (response.status === 200) {
          this.productsSearchResult = "";
          this.products = response.data.data.map((item) => {
            var discount =
              item.promo_descuento_perc != 0
                ? item.prices[0].Price * (item.promo_descuento_perc / 100)
                : 0;

            // check date of promotions
            var show_promo = false;
            if (item.promo_vencimiento_desde && item.promo_vencimiento_hasta) {
              var d = new Date(),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();
              if (month.length < 2) month = "0" + month;
              if (day.length < 2) day = "0" + day;
              var _now = [year, month, day].join("-");

              if (
                item.promo_vencimiento_desde <= _now &&
                item.promo_vencimiento_hasta >= _now
              ) {
                show_promo = true;
              }
            }

            var my_images =
              item.pictures.length > 0
                ? item.pictures.map((picture, index) => {
                    var type =
                      item.mime_type.length > 0 && item.mime_type[index] != null
                        ? item.mime_type[index]
                        : "";
                    var is_video = type.includes("video") ? true : false;
                    var ext = type.split("/").pop();

                    return is_video
                      ? `${
                          import.meta.env.VITE_IMAGES_BASE_URL
                        }/storage/videos/${item.image_hash[index]}.${ext}`
                      : `${
                          import.meta.env.VITE_IMAGES_BASE_URL
                        }/images/thumbs/${picture}`;
                  })
                : [];

            return {
              id: item.id,
              name: item.name,
              quantity: item.quantity,
              slug: item.slug_producto,
              description: item.description,
              image: my_images[0] != undefined ? my_images[0] : null,
              pictures: my_images,
              mime_type:
                typeof item.mime_type !== "undefined"
                  ? item.mime_type.length > 0
                    ? item.mime_type.map((type) => `${type}`)
                    : []
                  : [],
              url: item.url?.map((item) =>
                item !== null
                  ? `${
                      item.indexOf("?v=") != -1
                        ? "https://www.youtube.com/embed/" +
                          item.substring(item.indexOf("=") + 1, item.length)
                        : item.indexOf("?si=") != -1
                        ? "https://www.youtube.com/embed/" +
                          item.substring(
                            item.indexOf("?si="),
                            item.lastIndexOf("/") + 1
                          )
                        : item.indexOf("embed") != -1
                        ? "https://www.youtube.com/embed/" +
                          item.substring(item.lastIndexOf("/") + 1, item.length)
                        : "https://www.youtube.com/embed/" +
                          item.substring(item.lastIndexOf("/") + 1, item.length)
                    }`
                  : item
              ),
              for_video: `${
                import.meta.env.VITE_IMAGES_BASE_URL
              }/images/thumbs/video_preview.png`,
              category: item.category,
              category_id: item.category_id,
              subcategory: item.subCategory,
              subcategory_id: item.sub_category_id,
              price: item.prices[0].Price,
              prices: item.prices,
              show_promo: show_promo,
              promo_descuento_perc: item.promo_descuento_perc,
              discount: discount,
              promo_price: item.prices[0].Price - discount,
              href: `/productos/${item.slug_producto}`,
              presentaciones: item.presentaciones,
              arr_prices: item.arr_prices,
              marca: item.marca,
              categoria_pago_requerido: item.categoria_pago_requerido,
              subCategoria_pago_requerido: item.subCategoria_pago_requerido,
            };
          });
          this.products = this.products.filter((item) => item != null);
          this.productsSearchResult = this.products == "" ? [] : this.products;
        } else {
          console.log(response.error);
        }
      } else {
        console.log("stop searching!");
        this.searching = false;
      }

      this.searchingProducts = false;
    },

    stopSearch() {
      this.searchingProducts = false;
      this.searching = false;
    },

    clearSearch() {
      this.searchingProducts = false;
      this.searching = false;
      this.productSelected = null;
      this.productsSearchResult = [];
    },

    reload() {
      this.status = 200;
      this.loadingCategories = false;
      this.loadingSubcategories = false;
      this.loadingProducts = false;
      this.searchingProducts = false;
      this.loadingProductDetail = false;
      this.searching = false;
      this.criteria = "";
      this.categories = [];
      this.categorySelected = null;
      this.subcategories = [];
      this.subcategorySelected = null;
      this.productsOnPromo = [];
      this.productsFeatured = [];
      this.productBuffer = [];
      this.products = [];
      this.productSelected = null;
      this.productsSearchResult = [];
      this.crumbs = [];
      this.all_prices = [];
      this.productCurrentPage = 1;
      this.productLastPage = 1;
    },
  },
});
