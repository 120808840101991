<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import facebook from "/src/assets/img/networks/facebook.svg";
import instagram from "/src/assets/img/networks/instagram.svg";
import telegram from "/src/assets/img/networks/telegram.svg";
import tikTok from "/src/assets/img/networks/tiktok.svg";
import whatsapp from "/src/assets/img/networks/whatsapp.svg";
import youtube from "/src/assets/img/networks/youtube.svg";
import xlink from "/src/assets/img/networks/youtube.svg";

const props = defineProps({
  type: String,
  brands: Array,
  up: Boolean,
  id: Number,
});

const componentRef = ref();
const showLinks = ref(false);

onMounted(() => {
  window.addEventListener("click", listener);
});
onBeforeUnmount(() => {
  window.removeEventListener("click", listener);
});

function listener(ev) {
  if (
    ev.target !== componentRef.value &&
    ev.composedPath().includes(componentRef.value)
  ) {
    return;
  } else {
    showLinks.value = false;
  }
}
function getIcon(type) {
  switch (type) {
    case "facebook":
      return facebook;
    case "instagram":
      return instagram;
    case "telegram":
      return telegram;
    case "tikTok":
      return tikTok;
    case "whatsapp":
      return whatsapp;
    case "whatsapp2":
      return whatsapp;
    case "youtube":
      return youtube;
    default:
      return xlink;
  }
}
function getLink(type, item) {
  switch (type) {
    case "facebook":
      return item.facebook_link;
    case "instagram":
      return item.instagram_link;
    case "telegram":
      return item.telegram_link;
    case "tikTok":
      return item.tikTok_link;
    case "whatsapp":
      return item.whatsApp_link;
    case "whatsapp2":
      return item.whatsApp_link;
    case "youtube":
      return item.youtube_link;
    default:
      return item.x_link;
  }
}

function getShowBrand(type, item) {
  switch (type) {
    case "facebook":
      return item.facebook_link !== null;
    case "instagram":
      return item.instagram_link != null;
    case "telegram":
      return item.telegram_link != null;
    case "tikTok":
      return item.tikTok_link != null;
    case "whatsapp":
      return item.whatsApp_link != null;
    case "whatsapp2":
      return item.whatsApp_link != null;
    case "youtube":
      return item.youtube_link != null;
    default:
      return item.x_link != null;
  }
}

function showLinkMenu(event, type) {
  let linksMenu = document.querySelectorAll(".footer-network-link");
  if (linksMenu) {
    linksMenu.forEach((item) => {
      item.classList.add("d-none");
    });
    let network = document.getElementById(`link_${type}`);
    if (network) {
      network.classList.remove("d-none");
    }
  }
}
</script>

<template>
  <div class="absolute-wraper" ref="componentRef">
    <img
      class="network-btn"
      :src="getIcon(props.type)"
      :alt="props.type"
      @mouseover="showLinkMenu($event, props.type)"
      @click="showLinkMenu($event, props.type)"
    />

    <transition-group tag="div" name="link-list" appear>
      <div
        class="absolute-container left row footer-network-link d-none"
        :class="{
          up: props.up,
        }"
        :id="props.id"
        key="links"
        @mouseleave="showLinks = false"
      >
        <div v-for="(item, index) in props.brands">
          <div v-if="getShowBrand(props.type, item) == true">
            <a
              :key="`link_${index}`"
              :href="getLink(props.type, item)"
              target="_blank"
              class="link col-12"
            >
              {{ item.name }}
            </a>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>
